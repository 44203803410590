import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { BaseButton } from "components/Button/Atom";
import { BaseModal } from "components/Modal/Atom";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import ModalPortal from "components/ModalPortal";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { repairInfoRequestIDAtom } from "recoil/repairAtom";
import { colors, deviceSize } from "style/theme";
import timeUtil from "../../utils/timeUtil";
import ProcessModal from "./modal/ProcessModal";
import RepairModal from "./modal/RepairModal";
import FileModal from "./modal/file/FileModal";
import usePendingQuery from "./queries/usePendingQuery";
import useProcessMutation from "./queries/useProcessMutation";
import useProcessQuery from "./queries/useProcessQuery";
import useRepairMutation from "./queries/useRepairMutation";
import useRepairQuery from "./queries/useRepairQuery";

function Index() {
  const navigate = useNavigate();
  const ref = useRef<HTMLInputElement>(null);
  const previousLogRef = useRef<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRepairOpen, setIsRepairOpen] = useState(false);
  const [isProcessOpen, setIsProcessOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const { openModal, closeModal } = useModal();

  const repairInfoRequestID = useRecoilValue(repairInfoRequestIDAtom);

  const { isLoading: isRepairLoading, data: repairInfoData }: any =
    useRepairQuery(Number(repairInfoRequestID));

  const { data: pendingInfoData, refetch: pendingFetch }: any = usePendingQuery(
    repairInfoData?.data?.siteId
  );

  const { data: processInfoData, refetch: processFetch }: any =
    useProcessQuery();

  const { mutate: startMutate } = useRepairMutation("start");
  const { mutate: pendingMutate } = useRepairMutation("pending");
  const { mutate: resumeMutate } = useRepairMutation("resume");
  const { mutate: finishMutate } = useRepairMutation("finish");
  const { mutate: saveMutate } = useRepairMutation("save");
  const { mutate: resatrtMutate } = useRepairMutation("restart");

  const { mutate: processStartMutate } = useProcessMutation("start");
  const { mutate: processPendingMutate } = useProcessMutation("pending");
  const { mutate: processResumeMutate } = useProcessMutation("resume");
  const { mutate: processFinishMutate } = useProcessMutation("finish");

  const handleClick = (
    mutation: any,
    params: { ticketId: number; pendingReasonId?: number; processId?: number }
  ) => {
    mutation(params);
  };

  const saveParkingLocation = () => {
    saveMutate({
      ...repairInfoData.data,
      ticketId: repairInfoData?.data?.id,
      parkingLocation: ref.current?.value,
    });
  };
  const LastLogSpan = styled(LogDateSpan)`
    display: block;
    text-align: right;
    white-space: pre-line;
  `;

  return (
    <Container>
      {isRepairLoading ? (
        <LoadingSpinner />
      ) : (
        <Content>
          <CarInfoBox>
            <CarInfoWrapper>
              <Label>{"차량번호"}</Label>
              <CarInfoValue>{repairInfoData?.data?.plateCode}</CarInfoValue>
            </CarInfoWrapper>
            <CarInfoWrapper>
              <Label>{"VIN"}</Label>
              <CarInfoValue>{repairInfoData?.data?.vinCode}</CarInfoValue>
            </CarInfoWrapper>
            <CarInfoWrapper>
              <Label>{"모델명"}</Label>
              <CarInfoValue>{repairInfoData?.data?.carModel}</CarInfoValue>
            </CarInfoWrapper>
            <CarInfoWrapper>
              <Label>{"입고일시"}</Label>
              <CarInfoValue>
                {dayjs(repairInfoData?.data?.createdAt).format(
                  "YY-MM-DD HH:mm"
                )}
              </CarInfoValue>
            </CarInfoWrapper>
            <CarInfoWrapper>
              <Label>{"비고"}</Label>
              <CarInfoValue>{repairInfoData?.data?.context}</CarInfoValue>
            </CarInfoWrapper>
          </CarInfoBox>

          <CarRepairStateBox>
            <CarInfoWrapper>
              <Label>{"주차위치"}</Label>
              <ParkingTextInput
                defaultValue={repairInfoData?.data?.parkingLocation}
                ref={ref}
              />
              <BaseButton
                additionalCSS={SaveBtnCSS}
                onClick={saveParkingLocation}
              >
                {"저장"}
              </BaseButton>
            </CarInfoWrapper>

            {repairInfoData?.data?.stagesInfo?.map(
              (info: any, index: number) => (
                <RepairManagementBox
                  key={info.id}
                  shadow={info.id === repairInfoData?.data?.stageId}
                >
                  <GradientBar />
                  <RepairManagementWrapper>
                    <Label>{info[index]}</Label>
                    <RepairInfoValue>{info.name}</RepairInfoValue>
                    <RepairInfoDateBox>
                      {info.logs.map((log: any, logIndex: number) => {
                        let processName;
                        if (log.additional?.process?.name?.length > 0) {
                          previousLogRef.current =
                            log.additional?.process?.name;
                        }
                        processName = previousLogRef.current;
                        return (
                          <LogDateSpan key={log.id} stepStatus={log.stepStatus}>
                            {log.stepStatus === "IN_PROGRESS" &&
                            log.additional.oldStatus === "WAITING"
                              ? "정비 시작 "
                              : ""}
                            {log.stepStatus === "IN_PROGRESS" &&
                            log.additional.oldStatus === "PROCESSING"
                              ? `${processName} 완료 `
                              : ""}
                            {log.stepStatus === "IN_PROGRESS" &&
                            log.additional.oldStatus === "PENDING"
                              ? "작업 재개 "
                              : ""}
                            {log.stepStatus === "IN_PROGRESS" &&
                            log.additional.oldStatus === "DONE"
                              ? "정비 재시작 "
                              : ""}
                            {log.stepStatus === "PROCESSING" &&
                            log.additional.oldStatus !== "PROCESS_PENDING"
                              ? `${processName} 시작 `
                              : ""}
                            {log.stepStatus === "PROCESSING" &&
                            log.additional.oldStatus === "PROCESS_PENDING"
                              ? "작업 재개 "
                              : ""}
                            {log.stepStatus === "DONE" ? "정비 완료 " : ""}
                            {log.stepStatus === "PENDING" ||
                            log.stepStatus === "PROCESS_PENDING"
                              ? `${
                                  log.additional?.pendingReason?.name
                                } ${timeUtil(log.createdAt)}`
                              : timeUtil(log.createdAt)}
                            {log.stepStatus === "DONE" &&
                            logIndex === info.logs.length - 1 ? (
                              <LastLogSpan>{"모든 작업 완료"}</LastLogSpan>
                            ) : (
                              ""
                            )}
                          </LogDateSpan>
                        );
                      })}
                    </RepairInfoDateBox>
                  </RepairManagementWrapper>
                </RepairManagementBox>
              )
            )}
          </CarRepairStateBox>
          <RepairButtonBox>
            <RepairButtonWrapper>
              <FileUplodaBtn
                onClick={() => {
                  openModal(
                    <FileModal
                      onClose={() => closeModal({})}
                      ticketId={repairInfoData?.data?.id}
                    />,
                    null,
                    ""
                  );
                }}
              >
                파일 업로드
              </FileUplodaBtn>

              <WorkDetailBtn
                status={repairInfoData?.data?.stepStatus}
                onClick={() => {
                  repairInfoData?.data?.stepStatus === "PROCESSING"
                    ? handleClick(processFinishMutate, {
                        ticketId: repairInfoData?.data?.id,
                      })
                    : setIsProcessOpen(true);
                  processFetch();
                }}
              >
                {repairInfoData?.data?.stepStatus === "PROCESSING" ||
                repairInfoData?.data?.stepStatus === "PROCESS_PENDING"
                  ? "상세작업완료"
                  : "상세작업"}
              </WorkDetailBtn>
              <WorkPendingResumeBtn
                status={repairInfoData?.data?.stepStatus}
                onClick={() => {
                  repairInfoData?.data?.stepStatus === "PENDING" ||
                  repairInfoData?.data?.stepStatus === "PROCESS_PENDING"
                    ? handleClick(
                        repairInfoData?.data?.stepStatus === "PENDING"
                          ? resumeMutate
                          : processResumeMutate,
                        {
                          ticketId: repairInfoData?.data?.id,
                        }
                      )
                    : setIsRepairOpen(true);
                  pendingFetch();
                }}
                disabled={repairInfoData?.data?.stepStatus === "WAITING"}
              >
                {repairInfoData?.data?.stepStatus === "PENDING" ||
                repairInfoData?.data?.stepStatus === "PROCESS_PENDING"
                  ? "작업재개"
                  : "작업지연"}
              </WorkPendingResumeBtn>
              <WorkStartEndBtn
                status={repairInfoData?.data?.stepStatus}
                onClick={() =>
                  repairInfoData?.data?.stepStatus === "WAITING"
                    ? setIsModalOpen(true)
                    : handleClick(finishMutate, {
                        ticketId: repairInfoData?.data?.id,
                      })
                }
              >
                {repairInfoData?.data?.stepStatus === "WAITING"
                  ? "작업시작"
                  : "작업완료"}
              </WorkStartEndBtn>
              <WorkDoneRestartBtn
                status={repairInfoData?.data?.stepStatus}
                onClick={() =>
                  repairInfoData?.data?.stepStatus === "DONE"
                    ? handleClick(resatrtMutate, {
                        ticketId: repairInfoData?.data?.id,
                      })
                    : setIsModalOpen(true)
                }
              >
                {repairInfoData?.data?.stepStatus === "DONE"
                  ? "작업 재시작"
                  : undefined}
              </WorkDoneRestartBtn>
            </RepairButtonWrapper>
          </RepairButtonBox>
        </Content>
      )}
      <ConfirmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onClick={() => {
          handleClick(startMutate, {
            ticketId: repairInfoData?.data?.id,
          });
        }}
      />
      <RepairModal
        pendingInfo={pendingInfoData?.data}
        isOpen={isRepairOpen}
        setIsOpen={setIsRepairOpen}
        handleSubmit={(pendingReasonId) => {
          repairInfoData?.data.stepStatus === "PROCESSING"
            ? handleClick(processPendingMutate, {
                ticketId: repairInfoData?.data?.id,
                pendingReasonId: pendingReasonId,
              })
            : handleClick(pendingMutate, {
                ticketId: repairInfoData?.data?.id,
                pendingReasonId: pendingReasonId,
              });
          setIsRepairOpen(false);
        }}
      />
      <ProcessModal
        pendingInfo={processInfoData?.data.rows}
        isOpen={isProcessOpen}
        setIsOpen={setIsProcessOpen}
        handleSubmit={(processId) => {
          handleClick(processStartMutate, {
            ticketId: repairInfoData?.data?.id,
            processId: processId,
          });
          setIsProcessOpen(false);
        }}
      />
      {/* <FileModal
        isOpen={isUploadOpen}
        setIsOpen={setIsUploadOpen}
        ticketId={repairInfoData?.data?.id}
      /> */}
    </Container>
  );
}

const ConfirmModal = ({
  isModalOpen,
  setIsModalOpen,
  onClick,
}: {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // setRepairInfoRequestID?: SetterOrUpdater<string>;
  // navigate: NavigateFunction;
  // setQrData?: React.Dispatch<any>;
  onClick: () => void;
}) => {
  const onClose = () => {
    setIsModalOpen(false);
  };

  return isModalOpen ? (
    <ModalPortal>
      <BaseModal
        isOpen={isModalOpen}
        onClose={onClose}
        additionalCSS={ConfirmModalAdditionalCSS}
      >
        <ConfirmBox>
          <p>
            <span>작업을 시작하시겠습니까?</span>
            <span>작업 시작을 누르시면,</span>
            <span>차주에게 SMS 발송이 됩니다.</span>
          </p>

          <div className="buttonBox">
            <button onClick={onClose}>아니오</button>
            <button
              onClick={() => {
                onClick();
                setIsModalOpen(false);
              }}
            >
              시작
            </button>
          </div>
        </ConfirmBox>
      </BaseModal>
    </ModalPortal>
  ) : (
    <></>
  );
};

const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 80%;
  max-width: 150rem;
  height: 100%;
  margin-top: 3.5rem;
`;

const CarInfoBox = styled.div`
  height: 20em;
  /* margin-bottom: 5rem; */
  @media screen and (min-width: ${deviceSize.mobileM}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 2rem;
    grid-column-gap: 3rem;
  }
`;
const CarInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;

  &.note {
    align-items: initial;
  }

  & label {
    min-width: 20rem;
  }

  & span {
    word-break: break-all;
  }
`;
const Label = styled.label`
  color: white;
  font-size: 4.8rem;
  font-family: MBK CorpoA;
  font-weight: 400;
  letter-spacing: 0.336rem;
  word-wrap: break-word;
`;
const CarInfoValue = styled.span`
  color: white;
  font-size: 3.2rem;
  font-family: MBK CorpoS;
  font-weight: 400;
  letter-spacing: 0.032rem;
  word-wrap: break-word;
`;

const ParkingTextInput = styled.input`
  font-size: 3.2rem;
  padding: 0 2rem;
  width: 100%;
  height: 8rem;
  background: inherit;
  color: #ffffff;
`;

const LogDateSpan = styled.span<{ stepStatus?: string }>`
  color: ${({ stepStatus }) =>
    stepStatus === "PENDING" || stepStatus === "PROCESS_PENDING"
      ? "orange"
      : "white"};
`;
const CarRepairStateBox = styled.div`
  margin-top: 13rem;
  height: 60em;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 1rem;
  }

  ::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.23);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(217, 217, 217, 0.44);
  }

  @media (min-width: 640px) {
    height: 40em;
  }
  @media (min-width: 480px) {
    height: 30em;
  }
  @media (min-width: 280px) {
    height: 30em;
  }
`;

const GradientBar = styled.div`
  width: 100%;
  height: 0.8rem;
  margin: 1.1rem 0;
  border-bottom: 0.5rem solid transparent;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #565656,
    #ffffff,
    #565656,
    rgba(255, 255, 255, 0)
  );
  background-clip: content-box;
`;

// 회전 애니메이션을 정의
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// 스타일드 컴포넌트 생성
const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 0.8s linear infinite;
  margin: 20px auto;
`;

const RepairManagementBox = styled.div<any>`
  padding-bottom: 1.1rem;
  background: ${(props) =>
    props.shadow === true && "rgba(255, 255, 255, 0.05)"};
  box-shadow: ${(props) =>
    props.shadow === true &&
    "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;"};
`;
const RepairManagementWrapper = styled(CarInfoWrapper)`
  display: flex;
  justify-content: space-between;
  & label {
    min-width: initial;
  }
`;
const RepairInfoValue = styled(CarInfoValue)`
  font-size: 4rem;
  flex: 1;
`;
const RepairInfoDateBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 3rem;
  gap: 0.2rem;

  & span {
    font-family: MBK CorpoS;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
  }
`;

const RepairButtonBox = styled.div`
  width: 100%;
  display: flex;
  margin-top: 8.5rem;
  justify-content: flex-end;
  height: 10em;
  margin-bottom: 1rem;
`;

const RepairButtonWrapper = styled.div`
  width: 50%;
  display: flex;
  gap: 3rem;
  justify-content: flex-end;
`;

const SaveBtnCSS = css`
  height: 8rem;
  font-size: 3.2rem;
  background-color: #505254;
`;

const ConfirmModalAdditionalCSS = css`
  & .modal-content-box {
    width: 80%;
    max-width: 100rem;
    height: 80%;
    max-height: 80rem;
    background-color: initial;
    box-shadow: initial;
  }
`;

const ConfirmBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & p {
    display: flex;
    flex-direction: column;

    text-align: center;
    color: white;
    font-size: 3.2rem;
    font-family: ABeeZee;
    font-weight: 400;
    letter-spacing: 0.032rem;
    word-wrap: break-word;
  }

  & .buttonBox {
    display: flex;
    margin-top: 6rem;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: ${deviceSize.tablet}) {
      flex-direction: row;
    }

    gap: 3rem;

    button {
      width: 27.6rem;
      height: 6.2rem;
      border-radius: initial;

      background: linear-gradient(
        180deg,
        rgba(211.69, 237.34, 245.44, 0.38) 0%,
        rgba(60.03, 87.02, 127.5, 0.23) 100%
      );

      color: white;
      font-size: 3.2rem;
      font-family: ABeeZee;
      font-weight: 400;
      letter-spacing: 0.032rem;
      word-wrap: break-word;
    }
  }
`;

const WorkStartEndBtn = styled<any>(BaseButton)`
  display: ${(props) =>
    props.status === "PENDING"
      ? "none"
      : props.status === "DONE"
      ? "none"
      : "block"};
  height: 100%;
  font-size: 3.2rem;
  background-color: ${(props) =>
    props.status === "WAITING"
      ? colors.pointColorGreen
      : props.status === "IN_PROGRESS"
      ? colors.pointColorGreen
      : props.status === "DONE"
      ? colors.pointColorBlue
      : props.status === "PROCESSING"
      ? colors.pointColorGreen
      : colors.pointColorGreen};
`;

const WorkDoneBtn = styled<any>(BaseButton)`
  display: ${(props) => (props.status === "DONE" ? "block" : "none")};
  height: 100%;
  font-size: 3.2rem;
  background-color: ${colors.pointColorBlue};
`;

const WorkDoneRestartBtn = styled<any>(BaseButton)`
  display: ${(props) => (props.status === "DONE" ? "block" : "none")};
  height: 100%;
  font-size: 3.2rem;
  background-color: ${colors.pointColorBlueGrey};
`;

const WorkPendingResumeBtn = styled<any>(BaseButton)`
  display: ${(props) => (props.status === "DONE" ? "none" : "block")};
  height: 100%;
  font-size: 3.2rem;
  background-color: ${(props) =>
    props.status === "WAITING"
      ? colors.pointColorYellow
      : props.status === "IN_PROGRESS"
      ? colors.pointColorYellow
      : props.status === "PROCESSING"
      ? colors.pointColorYellow
      : colors.pointColorBlueGrey};
  opacity: ${(props) => (props.status === "WAITING" ? 0.3 : 1)};
  cursor: ${(props) =>
    props.status === "WAITING" ? "not-allowed" : "pointer"};
`;

const WorkDetailBtn = styled<any>(BaseButton)`
  display: ${(props) =>
    props.status === "WAITING"
      ? "none"
      : props.status === "DONE"
      ? "none"
      : props.status === "PENDING"
      ? "none"
      : props.status === "PROCESS_PENDING"
      ? "none"
      : "block"};
  height: 100%;
  font-size: 3.2rem;
  background-color: ${(props) =>
    props.status === "PROCESSING"
      ? colors.pointColorBlack
      : colors.pointColorPastelBlue};
`;

const FileUplodaBtn = styled<any>(BaseButton)`
  height: 100%;
  font-size: 3.2rem;
  background-color: royalblue;
`;
export default Index;
