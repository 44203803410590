import styled from "@emotion/styled";
import { BasePagination as Pagination } from "components/Pagination/Atom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { repairInfoRequestIDAtom } from "recoil/repairAtom";
import { useTicketGetQuery } from "../queries/useTicketQuery";

export const ReceptionCar = () => {

    const navigate = useNavigate();
    const setRepairInfoRequestID = useSetRecoilState(repairInfoRequestIDAtom);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchScope, setSearchScope] = useState('');

    const { data } = useTicketGetQuery({
        activePage: currentPage,
        search: searchTerm,
        searchFields: [searchScope]
    });

    const handleClickTicketId = (ticketId: string) => {
        setRepairInfoRequestID(ticketId);
        navigate('/repair')
    }

    return (
        <>
            <Container>
                <ContainerContent>
                    <Title>접수 차량 목록</Title>
                    <SearchBox>
                        <SearchSelect value={searchScope} onChange={(e) => setSearchScope(e.target.value)}>
                            <option value="">전체</option>
                            <option value="plateCode">차량번호</option>
                            <option value="carModel">차량모델</option>
                            <option value="customerName">어드바이저명</option>
                        </SearchSelect>
                        <SearchInput 
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </SearchBox>
                </ContainerContent>
                <Table>
                    <Thead>
                        <Tr>
                            <Th flex={1}>차량번호</Th>
                            <Th flex={1}>모델명</Th>
                            <Th flex={1}>어드바이저 명</Th>
                            <Th flex={1}>접수일시</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data?.data?.rows.map((item: any) => {
                            return (
                                <TbodyTr onClick={() => handleClickTicketId(item.id)}>
                                    <Td flex={1}>{item.plateCode}</Td>
                                    <Td flex={1}>{item.carModel}</Td>
                                    <Td flex={1}>{item.customerName}</Td>
                                    <Td flex={1}>{new Date(new Date(item.createdAt).getTime() + 9 * 60 * 60 * 1000).toISOString().replace('T', ' ').split('.')[0]}</Td>
                                </TbodyTr>
                            )

                        })}
                    </Tbody>
                    <Tfoot>
                        <Pagination
                            totalData={data?.data?.total}
                            countPerPage={10}
                            activePage={currentPage}
                            currentPg={currentPage}
                            notifier={(_currentPage) => {
                                setCurrentPage(_currentPage);
                            }}
                        />
                    </Tfoot>
                </Table>
            </Container>
        </>
    )
}

const Container = styled.div`
    width : 100%;
    height : 100%;
    padding : 12px 48px;
    color: white;
    font-family: MBK CorpoA;
`;

const ContainerContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width : 100%;
    height : 100%;
`;

const SearchBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width : 30%;
    height : 32px;
`;

const SearchSelect = styled.select`
    width : 20%;
    height : 100%;
    border-radius: 8px;
    margin-right: 8px;    
`

const SearchInput = styled.input`
    width : 80%;
    height : 100%;
    border-radius: 8px;
    margin-right: 8px;
    padding : 8px;
`

const Title = styled.p`
    font-size : 4.8rem;
    font-weight : 700;
`;

const Table = styled.table`
    width : 100%;
    height : 100%;
    padding : 20px 0;
`;

const Thead = styled.thead`
    width : 100%;
    height : 100%;
`;

const Tr = styled.tr`
    width : 100%;
    height : 48px;
    display: flex;
    border-bottom : 1px solid white;
    cursor : pointer;
`;

const TbodyTr = styled.tr`
    width : 100%;
    height : 48px;
    display: flex;
    border-bottom : 1px solid white;
    cursor : pointer;
    &:hover {
        background-color: gray;
    }
`

const Th = styled.th<{ flex: number }>`
    flex : ${props => props.flex};
    font-size : 3.2rem;
    height : 3.2rem;
    line-height: 3.4rem;
`;

const Tbody = styled.tbody`
    width : 100%;
    height : 100%;
`;

const Td = styled.td<{ flex: number }>`
    flex : ${props => props.flex};
    font-size : 3.2rem;
    display: flex;
    align-items: center;
    justify-content: left;
`;

const Tfoot = styled.tfoot`
    padding : 16px 0;
    display: flex;
    width : 100%;
    justify-content: center;
    align-items: center;
`