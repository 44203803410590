import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { BaseModal } from "components/Modal/Atom";
import ModalPortal from "components/ModalPortal";
import { useAddECSCommand } from "hook/useAddECSCommand";
import { useState } from "react";
import { colors, deviceSize } from "style/theme";

// 나중에 Context를 활용해서 해도 괜찮을것 같다..

const RepairModal = ({
  isOpen,
  setIsOpen,
  pendingInfo,
  handleSubmit,
}: {
  isOpen: boolean;
  pendingInfo?: Array<{
    id: number;
    name: string;
    modifiedAt: string;
    createdAt: string;
  }>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: any) => void;
}) => {
  const [pendingReason, setPendingReason] = useState<{
    id: number;
    name: string;
  }>({ id: 0, name: "" });
  // const formData = useRef<Map<string, any>>(new Map());

  useAddECSCommand(() => {
    setIsOpen(false);
  });

  const onSubmit = () => {
    handleSubmit(pendingReason.id);
  };

  return isOpen ? (
    <ModalPortal>
      <BaseModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContentWrapper>
          <ModalHeader>
            <h2>{"지연 사유 선택"}</h2>
          </ModalHeader>
          <GradientBar />
          <SelectedCenterBox>
            <select
              className="workbayName"
              onChange={(event) => {
                const selectedPending = pendingInfo?.find(
                  (pending) => pending.name === event.target.value
                );
                if (selectedPending) {
                  setPendingReason({
                    id: selectedPending.id,
                    name: selectedPending.name,
                  });
                }
              }}
            >
              <option selected disabled>지연사유를 선택해주세요</option>
              
              {pendingInfo?.map((pending) => (
                <option key={pending.id} value={pending.name}>
                  {pending.name}
                </option>
              ))}
            </select>
            {/* <div className="workbayName">
              {pendingReason.name ?? "센터를 선택해주세요"}
              <ul>
                {pendingInfo?.map((pending) => (
                  <li
                    key={pending.name}
                    onClick={() =>
                      setPendingReason({
                        id: pending.id,
                        name: pending.name,
                      })
                    }
                  >
                    {pending.name}
                  </li>
                ))}
              </ul>
            </div> */}
            <DarkBtn
              onClick={() => {
                pendingReason.name && onSubmit();
              }}
            >
              완료
            </DarkBtn>
          </SelectedCenterBox>
        </ModalContentWrapper>
      </BaseModal>
    </ModalPortal>
  ) : (
    <></>
  );
};

export default RepairModal;

const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  & h2 {
    color: #eef0f4;
    font-size: 48px;
    font-family: MBK CorpoA;
    font-weight: 300;
    word-wrap: break-word;
  }

  & .options {
    & .icon-box {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;

const GradientBar = styled.div`
  width: 100%;
  height: 0.8rem;
  margin: 1.1rem 0;
  border-bottom: 0.5rem solid transparent;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #565656,
    #ffffff,
    #565656,
    rgba(255, 255, 255, 0)
  );
  background-clip: content-box;
`;

const ModalFiledset = styled.fieldset`
  border: none;
  display: flex;
  margin-top: 26.5px;
  width: 100%;
  gap: 20px;
`;

const DropDownBox = styled.div`
  width: 90%;
`;

const DropdownCSS = css`
  font-size: 48px;
  font-weight: 300;
  font-family: MBK CorpoA;
  padding: 20px 40px;
  height: 60px;
`;

const SelectedCenterBox = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  position: relative;

  & label {
    color: white;
    font-size: 3.6rem;
    font-family: MBK CorpoA;
    font-weight: 400;
    letter-spacing: 0.336rem;
    word-wrap: break-word;
  }

  & .workbayName {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    color: white;
    font-size: 3.6rem;
    font-family: MBK CorpoA;
    font-weight: 400;
    letter-spacing: 0.336rem;
    word-wrap: break-word;
    background: ${colors.grayGradient_100};
    padding: 1.6rem;
    & option {
      background-color: ${colors.black};
    }
  }
`;

const DarkBtn = styled.button`
  width: 10rem;
  height: 100%;

  color: white;
  font-size: 3.2rem;
  font-family: MBK CorpoS;
  font-weight: 400;
  letter-spacing: 0.032rem;
  word-wrap: break-word;
  border: 0.4rem solid transparent;
  border-image: linear-gradient(-10deg, #ffffff, #6c6a6a);
  border-image-slice: 1;

  @media screen and (min-width: ${deviceSize.tablet}) {
    width: 20rem;
  }
`;

const ModalConfirmBtnBox = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  & button {
    width: 124px;
    height: 60px;
    background-color: ${colors.black};
    color: white;
    border: 4px solid ${colors.white};
    border-radius: 6px;
    font-size: 32px;
    font-weight: 400;
    font-family: MBK SorpoA;
  }
`;
