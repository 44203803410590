import styled from "@emotion/styled";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { repairInfoRequestIDAtom } from "recoil/repairAtom";
import useScanQR from "./hook/useScanQR";
import { ReceptionCar } from "./table";

function QR() {
  const { qrData, setQrData } = useScanQR();
  const navigate = useNavigate();
  const setRepairInfoRequestID = useSetRecoilState(repairInfoRequestIDAtom);

  useEffect(() => {
    if (qrData) {
      setRepairInfoRequestID(qrData);
      navigate("/repair");
    }
  }, [qrData]);

  return (
    <Container>
      <Title>QR 코드 인식</Title>
      <QRBox id="qr-reader" />
      <ReceptionCar />
    </Container>
  );
}

const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  color: white;
  font-size: 4.8rem;
  font-family: MBK CorpoA;
  font-weight: 400;
  letter-spacing: 0.336rem;
  word-wrap: break-word;
  padding: 3.3rem 0;
`;

const QRBox = styled.div`
  width: 50%;
  height: 50%;
  width: 62.7rem;
  height: 62.7rem;
  overflow: hidden;

  & video {
    width: 100%;
    height: 100%;
  }
`;

export default QR;
