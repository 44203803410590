import axios, { AxiosError } from "axios";
import { authEnum } from "config/auth";
import { BASE_URL } from "instance/axios";
import { AddedFileProps, FileModalForm } from "./FileModalForm";

const FileModal = ({
  onClose,
  ticketId,
}: {
  onClose: () => void;
  ticketId: number;
}) => {
  const onSave = async (files: AddedFileProps[]) => {
    const formData = new FormData();

    Array.from(files).forEach((file: any) => {
      formData.append("file", file);
    });

    const sizePlus = files.reduce((total, file) => total + file.size, 0);

    if (sizePlus <= 10 * 1024 * 1024) {
      await axios({
        method: "post",
        url: `${BASE_URL}/ticket/${ticketId}/attachment`,
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(authEnum.authToken)}`,
        },
      })
        .then((result: any) => {
          if (result.status === 200) {
            alert("업로드에 성공하셨습니다.");
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
        });
      onClose();
    } else {
      alert("파일 용량이 초과되었습니다.");
    }
  };

  return (
    <FileModalForm onClose={onClose} ticketId={ticketId} onSave={onSave} />
  );
};

export default FileModal;
