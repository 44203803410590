import { MantineProvider } from "@mantine/core";
import ModalStackManager from "components/Modal/Atom/context/ModalStackManager";

import QueryProvider from "provider/QueryProvider";
import ThemeProvider from "provider/ThemeProvider";
import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import Global from "style/Global";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <QueryProvider>
        <ThemeProvider>
          <MantineProvider>
            <ModalStackManager>
              <Global />
              <App />
            </ModalStackManager>
          </MantineProvider>
        </ThemeProvider>
      </QueryProvider>
    </RecoilRoot>
  </React.StrictMode>
);
