import styled from "@emotion/styled";

interface FileDropZoneBoxTitleProps {
  children: React.ReactNode;
  align: "left" | "center" | "right";
}

export const FileDropZoneBoxTitle = ({
  children,
  align,
}: FileDropZoneBoxTitleProps) => {
  return <Title align={align}>{children}</Title>;
};

const Title = styled.div<{ align: "left" | "center" | "right" }>`
  font-weight: 600;
  font-size: 20px;
  display: flex;
  color: #ffffff;
  text-align: ${(props) => (props.align ? props.align : "center")};
  display: flex;
  justify-content: space-between;
`;
