import { SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { PropsWithChildren } from "react";

interface Props {
  isOpen?: boolean;
  onClose?: (...args: any) => any;
  additionalCSS?: SerializedStyles;
}

function BaseModal({
  isOpen,
  onClose,
  additionalCSS,
  children,
}: PropsWithChildren<Props>) {
  return (
    <ModalContainer isOpen={isOpen} additionalCSS={additionalCSS}>
      <Overlay className="modal-overlay" onClick={onClose} />
      <ContentBox className="modal-content-box">{children}</ContentBox>
    </ModalContainer>
  );
}

const ModalContainer = styled.section<Pick<Props, "isOpen" | "additionalCSS">>`
  width: 100%;
  height: 100%;
  position: fixed;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: var(--zIndex-0st);

  ${({ additionalCSS }) => additionalCSS && additionalCSS}
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 0;
`;

const ContentBox = styled.div`
  width: 100%;
  max-width: 80%;
  height: auto;
  background-color: #000;
  box-shadow: 0px 4px 20px rgba(170, 169, 184, 0.1);
  border-radius: 12px;
  padding: 20px 24px;
  z-index: 100;
`;

export default BaseModal;
