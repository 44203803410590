import axios from "axios";
import { ROLE_MAP } from "components/Layout/Footer";
import { authEnum } from "config/auth";
import { BASE_URL } from "instance/axios";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { authTokenAtom } from "recoil/authAtom";
import { FooterInfo } from "recoil/layoutAtom";
import deCodeToken from "utils/decodeToken";

type USER_ROLES = "ADMIN" | "CENTER_ADMIN" | "TECHNICIAN" | "ADVISER";

function useGetAuthToken(
  loginInform: { identifier: string; password: string },
  loginCallback: ({
    centerName,
    managerName,
    workbayName,
  }: Partial<FooterInfo>) => void
) {
  const queryClient = useQueryClient();
  const setRecoilAuthToken = useSetRecoilState(authTokenAtom);
  const navigate = useNavigate();
  const { isLoading: isLoginLoading, refetch: loginRefetch } = useQuery({
    queryKey: ["authToken", loginInform],
    queryFn: () =>
      axios.post(`${BASE_URL}/auth/logIn`, loginInform),
    staleTime: 0,
    cacheTime: 0,
    enabled: false,
    onSuccess: async (data) => {
      const authToken = data.data?.token;
      const userInform = deCodeToken(authToken);

      localStorage.setItem("siteId", userInform.user.siteId);
      localStorage.setItem(authEnum.authToken, authToken);
      setRecoilAuthToken(authToken);

      const result = await axios.get(`${BASE_URL}/whoami`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      loginCallback({
        centerName: result.data.site?.name,
        managerName: `${ROLE_MAP[result.data.role as USER_ROLES]} ${result.data.name
          }`,
        workbayName: result.data.workbay?.name,
      });
      navigate("/qr");
    },
    onError(data: any) {
      if (isLoginLoading) {
        alert(data.response.data.message);
      }
    },
  });
  return {
    isLoginLoading,
    loginRefetch,
  };
}

export default useGetAuthToken;
