import styled from "@emotion/styled";
import axios from "axios";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import BaseInstance, { BASE_URL } from "instance/axios";
import { icons } from "modules/icons";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { GoUpload } from "react-icons/go";
import { IoMdEye } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { PreviewForm } from "../preview/PreviewForm";
import { FileBox } from "./FileBox";
import { FileBoxButton } from "./FileBoxButton";
import { FileBoxIcon } from "./FileBoxImage";
import { FileBoxInform } from "./FileBoxInform";
import { FileBoxListWrapper } from "./FileBoxListWrapper";
import { FileButton } from "./FileButton";
import { FileDropZone } from "./FileDropZone";
import { FileDropZoneBox } from "./FileDropZoneBox";
import { FileDropZoneBoxTitle } from "./FileDropZoneBoxTitle";
import { FileDropZoneDescription } from "./FileDropZoneDescription";
import { FileDropZoneTitle } from "./FileDropZoneTitle";
import { FileMain } from "./FileMain";

const File = Object.assign(FileMain, {
  DropZoneBoxTitle: FileDropZoneBoxTitle,
  DropZoneBox: FileDropZoneBox,
  DropZone: FileDropZone,
  DropZoneTitle: FileDropZoneTitle,
  DropZoneDescription: FileDropZoneDescription,
  Box: FileBox,
  BoxButton: FileBoxButton,
  BoxIcon: FileBoxIcon,
  BoxInform: FileBoxInform,
  BoxWrapper: FileBoxListWrapper,
  SaveButton: FileButton,
});

export interface FileProps {
  createdAt?: string;
  fileName: string;
  filePath: Blob;
  id: number;
  tempId?: number;
  modifiedAt?: string;
  ticketId?: number;
  size?: number;
  preview?: string;
}

export interface AddedFileProps {
  path?: Blob;
  lastModified?: number;
  lastModifiedDate?: Date;
  id?: number;
  tempId?: number;
  name: string;
  size: number;
  type: string;
  webkitRelativePath?: string;
  preview?: string;
}

interface FileModalFormProps {
  onClose: () => void;
  onSave: (files: AddedFileProps[]) => void;
  ticketId?: number;
}

export const FileModalForm = ({
  onClose,
  onSave,
  ticketId,
}: FileModalFormProps) => {
  const { openModal, closeModal } = useModal();
  const [files, setFiles] = useState<FileProps[]>([]);
  const [addedFiles, setAddedFiles] = useState<AddedFileProps[]>([]);
  const [uploadFiles, setUploadFiles] = useState<any>("");
  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 10 * 1024 * 1024,
    maxFiles: 5,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png", ".bmp"],
      "application/pdf": [".pdf"],
    },
    onDrop: (acceptedFiles: AddedFileProps[], fileRejections) => {
      // 업로드된 파일에 고유한 키값 추가 (tempId)
      acceptedFiles.forEach((file, idx) => {
        Object.assign(file, {
          tempId: ~~(Math.random() * 10000000),
          preview: URL.createObjectURL(file as any),
        });
      });
      setAddedFiles((prevAddedFiles) => [...prevAddedFiles, ...acceptedFiles]);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const convertAddedFileToFileList = addedFiles.map((addedFile) => {
    console.log(addedFile, "addedFile");
    return {
      fileName: addedFile.name,
      size: addedFile.size,
      type: addedFile.type,
      id: undefined, //addedFile.id, // 랜덤 데이터
      tempId: addedFile.tempId,
      filePath: addedFile.path,
      preview: addedFile.preview,
    };
  });

  const getAttachment = () => {
    BaseInstance.attachmentsGet(
      {},
      {
        params: {
          query: JSON.stringify({
            ticketId: ticketId,
          }),
        },
      }
    ).then((result: any) => {
      if (result.data.rows.length > 0) {
        setFiles(result.data.rows);
      }
    });
  };

  const disabledBtn = addedFiles.length === 0;

  const handleSave = () => {
    onSave(addedFiles);
  };

  useEffect(() => {
    getAttachment();
  }, []);

  const handleClickFile = (file: any) => {
    // 파일 다운로드 API
    axios
      .get(`${BASE_URL}/ticket/attachment/${file.id}`, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((response) => {
        setUploadFiles(response);
      });
  };

  const downloadFile = (data: Blob, fileName: string, mime: string) => {
    const blob = new Blob([data], { type: mime });
    const url = window.URL.createObjectURL(blob);

    if (mime === "application/pdf") {
      openModal(
        <PreviewForm
          file={blob}
          fileName={fileName}
          onClose={() => closeModal({})}
        />,
        "",
        ""
      );
    } else {
      const newTab = window.open() as Window;
      newTab.document.body.innerHTML = `<img src="${url}" alt="${fileName}" style="width: 100%; height: 100%; object-fit: contain;">`;
      // 이미지를 클릭하면 다운로드 시작
      newTab.document.body.onclick = () => {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        newTab.document.body.appendChild(link);
        link.click();
        newTab.document.body.removeChild(link);
      };
    }
  };

  useEffect(() => {
    if (uploadFiles) {
      downloadFile(
        uploadFiles.data,
        uploadFiles?.fileName as string,
        uploadFiles.headers?.["content-type"]
      );
    }
  }, [uploadFiles]);

  return (
    <>
      <File>
        <File.DropZoneBox>
          <File.DropZoneBoxTitle align="left">
            {"첨부파일 업로드"}
            <icons.Close_Icon onClick={onClose} />
          </File.DropZoneBoxTitle>
          <File.DropZone>
            <DropZone {...getRootProps()}>
              <input {...getInputProps()} />
              <File.DropZoneTitle>
                <GoUpload color="#d8d3d3" size={15} />
                <p>{"Drop items here or Browse files"}</p>
              </File.DropZoneTitle>
            </DropZone>
          </File.DropZone>
          <File.DropZoneDescription>
            <p>{"Up to 10MB pdf, jpg, jpeg, png, bmp"}</p>
          </File.DropZoneDescription>
        </File.DropZoneBox>
        <File.BoxWrapper>
          {[...files, ...convertAddedFileToFileList].map((file, index) => {
            function handleDelete(arg0: {
              fileName: string;
              id: any;
              tempId: any;
            }): void {
              if (window.confirm("파일을 삭제하시겠습니까?")) {
                // 임시 업로드된 파일 데이터와 업로드된 파일 데이터의 삭제를 분기 처리한다.
                if (file.id) {
                  setFiles((prevFiles) =>
                    prevFiles.filter((file, index) => index !== arg0.id)
                  );
                  BaseInstance.attachmentsAttachmentIdDelete(arg0.id).then(
                    (result) => {
                      if (result.status === 200) {
                        alert("파일이 삭제되었습니다.");
                        onClose();
                      }
                    }
                  );
                } else {
                  setAddedFiles((prevAddedFiles) =>
                    prevAddedFiles.filter((file) => file.tempId !== arg0.tempId)
                  );
                }
              }
            }

            return (
              <File.Box key={file.id ?? file.tempId}>
                <File.BoxInform>
                  <p>
                    {file.fileName}
                    {index >= files.length && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                  </p>
                </File.BoxInform>
                <File.BoxButton
                  icon={<IoMdEye size={25} color="#FFFFFF" />}
                  onClick={() =>
                    index >= files.length
                      ? alert("저장 후 미리보기가 가능합니다.")
                      : handleClickFile(file)
                  }
                />
                <File.BoxButton
                  icon={<IoCloseOutline size={25} color="#FFFFFF" />}
                  onClick={() =>
                    // tempId는 임시 업로드된 파일의 고유한 키 값이다.
                    handleDelete({
                      fileName: file.fileName,
                      id: file.id,
                      tempId: file?.tempId,
                    })
                  }
                />
              </File.Box>
            );
          })}
        </File.BoxWrapper>
        <File.SaveButton disabled={disabledBtn} onClick={handleSave}>
          {"저장"}
        </File.SaveButton>
      </File>
    </>
  );
};

const DropZone = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px dashed #3b4758;
`;
