import { Routes, Route, useLocation, Outlet } from "react-router-dom";
import Auth from "pages/auth";
import QR from "pages/qr";
import Repair from "pages/repair";
import ProtectedRoute from "components/Router/ProtectedRoute";
import { useCheckAuth } from "utils/checkAuth";
import styled from "@emotion/styled";
import Header from "components/Layout/Header";
import Footer from "components/Layout/Footer";
import { useRecoilValue } from "recoil";
import { repairInfoRequestIDAtom } from "recoil/repairAtom";

function Index() {
  useLocation();
  const auth = useCheckAuth();
  const repairInfoRequestID = useRecoilValue(repairInfoRequestIDAtom);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          path="/"
          element={
            <ProtectedRoute redirectCondition={auth} redirectPath="/qr">
              <Auth />
            </ProtectedRoute>
          }
        />
        <Route
          path="/qr"
          element={
            <ProtectedRoute redirectCondition={!auth} redirectPath="/">
              <QR />
            </ProtectedRoute>
          }
        />
        <Route
          path="/repair"
          element={
            <ProtectedRoute
              redirectCondition={repairInfoRequestID === ""}
              redirectPath="/qr"
            >
              <Repair />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
}

const Layout = () => {
  return (
    <Main>
      <Header />
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </Main>
  );
};
const Main = styled.main`
  min-width: 100vw;
  min-height: 100vh;
  width: 100vw;
  /* height: 100vh; */
  background: ${({ theme }) => theme.backgroundColor};
  background-color: #4d4d4d;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
`;

export default Index;
