import styled from "@emotion/styled";
import React from "react";

interface FileDropZoneProps {
  children: React.ReactNode;
  styles?: React.CSSProperties;
}

export const FileDropZone = ({ children }: FileDropZoneProps) => {
  return <FileDropZoneWrapper>{children}</FileDropZoneWrapper>;
};

const FileDropZoneWrapper = styled.div`
  width: 100%;
`;
