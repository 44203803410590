import axios from "axios";
import {
  Configuration,
  DefaultApi,
} from "@sizlcorp/mbk-api-document/dist/models";
export const BASE_URL =
  process.env.REACT_APP_BASE_URL ?? "https://mbk.sizl.co.kr/api";

export const axiosInstance: any = axios.create({
  baseURL: BASE_URL,
  timeout: 1000 * 60 * 60 * 24 * 7,
});

// Add an interceptor to the axios instance
axiosInstance.interceptors.request.use((req: any) => {
  const token = localStorage.getItem("authToken");
  req.headers["Authorization"] = "Bearer " + token;
  return req;
});
export const baseAxios = (url?: string) => {
  return new DefaultApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance
  );
};
const BaseInstance = baseAxios(BASE_URL);
export default BaseInstance;