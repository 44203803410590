import styled from "@emotion/styled";

interface FileBoxWrapperProps {
  children: React.ReactNode;
}

export const FileBoxListWrapper = ({ children }: FileBoxWrapperProps) => {
  return <FileBoxWrapper>{children}</FileBoxWrapper>;
};

const FileBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;
