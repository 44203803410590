import styled from "@emotion/styled";

interface FileBoxButtonProps {
  onClick: () => void;
  icon: React.ReactNode;
}

export const FileBoxButton = ({ icon, onClick }: FileBoxButtonProps) => {
  return <FileBoxButtonWrapper onClick={onClick}>{icon}</FileBoxButtonWrapper>;
};

const FileBoxButtonWrapper = styled.div`
  flex: 0 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
