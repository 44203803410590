import axios from "axios";
import { BASE_URL } from "instance/axios";
import { useQuery } from "react-query";

// useQuery에서 사용할 UniqueKey를 상수로 선언하고 export로 외부에 노출합니다.
// 상수로 UniqueKey를 관리할 경우 다른 Component (or Custom Hook)에서 쉽게 참조가 가능합니다.

export const QUERY_KEY = "/repair";


const fetch = (repairInfoRequestID: number) =>
  axios.get(`${BASE_URL}/tickets/${repairInfoRequestID}`, {
    params: {
      populate: ["stagesInfo"],
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });

const useRepairQuery = (repairInfoRequestID: number) => {
  return useQuery(QUERY_KEY, () => fetch(repairInfoRequestID));
};

export default useRepairQuery;
