import { Html5Qrcode } from "html5-qrcode";
import React, { useEffect, useState } from "react";

function useScanQR() {
  const [qrData, setQrData] = useState<any>("");
  let qrScanner: Html5Qrcode | null = null;

  useEffect(() => {
    qrScanner = new Html5Qrcode("qr-reader");
    qrScanner.start(
      { facingMode: "environment" },
      { fps: 10, qrbox: { width: 250, height: 250 } },
      (decodedText, decodedResult) => {
        setQrData(decodedText);
      },
      (errMessage) => {
        // console.log(errMessage);
      }
    );
  }, []);

  return { qrData, setQrData };
}

export default useScanQR;
