const deCodeToken = (token: string) => {
  if (!token) {
    return;
  }
  // Split the token and taken the second
  const base64Url = token.split(".")[1];
  // Replace "-" with "+"; "_" with "/"
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export default deCodeToken;
