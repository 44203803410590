import axios from "axios";
// import BaseInstance from "instance/axios";
import { useQuery } from "react-query";
import BaseInstance from "instance/axios";

// useQuery에서 사용할 UniqueKey를 상수로 선언하고 export로 외부에 노출합니다.
// 상수로 UniqueKey를 관리할 경우 다른 Component (or Custom Hook)에서 쉽게 참조가 가능합니다.

export const QUERY_KEY = "/pending";

const fetch = (siteId: number) =>
  BaseInstance.pendingReasonsFindPost({
    query: {
      site_id: siteId,
    },
  })

const usePendingQuery = (siteId: number) => {
  return useQuery(QUERY_KEY, () => fetch(siteId), {
    enabled: false,
  });
};

export default usePendingQuery;
