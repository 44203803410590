import styled from "@emotion/styled";

interface FileBoxInformProps {
  children: React.ReactNode;
  onClick?: (e: any) => void;
}
export const FileBoxInform = ({ children, onClick }: FileBoxInformProps) => {
  return (
    <FileBoxInformWrapper onClick={(e) => onClick && onClick(e)}>
      {children}
    </FileBoxInformWrapper>
  );
};

const FileBoxInformWrapper = styled.div`
  padding: 24px 10px;
  flex: 1 0 85%;
  height: 100%;
  color: #ffffff;

  p {
    font-size: 18px;
  }
`;
