import styled from "@emotion/styled";

interface FileBoxProps {
  children: React.ReactNode;
}

export const FileBox = ({ children }: FileBoxProps) => {
  return <FileBoxWrapper>{children}</FileBoxWrapper>;
};

const FileBoxWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  border: 1px solid #bdbbbb;
  border-radius: 2px;
`;
