import { authEnum } from "config/auth";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { authTokenAtom } from "recoil/authAtom";
import { footerInfoAtom, initialFooterInfo } from "recoil/layoutAtom";

export function useLogout() {
  const navigate = useNavigate();
  const setAuthTokenAtom = useSetRecoilState(authTokenAtom);
  const setFooterInfoAtom = useSetRecoilState(footerInfoAtom);

  return () => {
    setAuthTokenAtom("");
    setFooterInfoAtom(initialFooterInfo);
    localStorage?.removeItem(authEnum.authToken);
    localStorage?.removeItem('siteId');

    navigate("/");
  };
}
