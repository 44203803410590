import { useMutation, useQueryClient } from "react-query";
import { QUERY_KEY as repairQueryKey } from "./useRepairQuery";
// import BaseInstance from "instance/axios";
import axios from "axios";
import { BASE_URL } from "instance/axios";

type FetcherType = {
  stageId?: number;
  ticketId: number;
  stepStatus?: string;
  platCode?: number;
  customerName?: string;
  mobile?: string;
  vinCode?: string;
  context?: string;
  siteId?: string;
  pendingReasonId?: number;
  processReasonId?: number;
  parkingLocation?: string;
  processId?: number;
};

type MutationType = "start" | "pending" | "resume" | "finish"


//완료
const startFetcher = (params: FetcherType) =>
  // BaseInstance.ticketsTicketIdStartPut(params.ticketId);
  axios.put(
    `${BASE_URL}/ticket/${params.ticketId}/process_start`,
    {
      processId: params.processId,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    }
  );

const pendingFetcher = (params: FetcherType) =>
  axios.put(
    `${BASE_URL}/ticket/${params.ticketId}/process_pending`,
    {
      pendingReasonId: params?.pendingReasonId as number,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    }
  );

const resumeFetcher = (params: FetcherType) =>
  axios.put(`${BASE_URL}/ticket/${params.ticketId}/process_resume`, "", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });

//완료
const finishFetcher = (params: FetcherType) =>
  // BaseInstance.ticketsTicketIdFinishPut(params.ticketId);
  axios.put(`${BASE_URL}/ticket/${params.ticketId}/process_finish`, "", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });

const useProcessMutation = (
  mutationType: MutationType
) => {
  const queryClient = useQueryClient();

  let fetcherFunction: any;

  switch (mutationType) {
    case "start":
      fetcherFunction = startFetcher;
      break;
    case "pending":
      fetcherFunction = pendingFetcher;
      break;
    case "resume":
      fetcherFunction = resumeFetcher;
      break;
    case "finish":
      fetcherFunction = finishFetcher;
      break;
    default:
      throw new Error("Invalid mutation type");
  }

  return useMutation((params: Object) => fetcherFunction(params), {
    onSuccess: () => {
      let alertMessage = "";
      switch (mutationType) {
        case "start":
          alertMessage = "상세 작업이 시작되었습니다.";
          break;
        case "pending":
          alertMessage = "상세 작업이 지연되었습니다.";
          break;
        case "resume":
          alertMessage = "상세 작업이 재개되었습니다.";
          break;
        case "finish":
          alertMessage = "상세 작업이 완료되었습니다.";
          break;
        default:
          throw new Error("Invalid mutation type");
      }

      window.alert(alertMessage);
      queryClient.invalidateQueries(repairQueryKey);
    },
  });
};

export default useProcessMutation;
