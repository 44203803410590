import styled from "@emotion/styled";

interface FileDropZoneDescriptionProps {
  children: React.ReactNode;
  styles?: React.CSSProperties;
}

export const FileDropZoneDescription = ({
  children,
}: FileDropZoneDescriptionProps) => {
  return <DescriptionWrapper>{children}</DescriptionWrapper>;
};

const DescriptionWrapper = styled.div`
  font-size: 14px;
  color: #b5b3b3;
`;
