import { AxiosResponse } from "axios";
import BaseInstance from "instance/axios";
import { useQuery } from "react-query";

const TICKECT_GET_QUERY_KEY = "/tickets";

const fetchTicketGet = (params? : any) => BaseInstance.ticketsGet(
    {},
    {
        params : {
            page : params.activePage ? params.activePage : 1,
            pageSize : 10,
            populate : ["createUser", "stagesInfo"],
            search : params.search ? `%${params.search}%` : "",
            searchFields : params.searchFields ? params.searchFields : ["plateCode", "carModel", "customerName"],
            sort : "-createdAt",
            query : {
                stepStatus : ["PENDING", "IN_PROGRESS", "PROGRESS_DONE", "WAITING", "PROCESSING"],
                siteId : localStorage.getItem("siteId")
            }
        }
    }
);

export const useTicketGetQuery = (params? : any) => {
    
    return useQuery<AxiosResponse>({
        queryKey: [TICKECT_GET_QUERY_KEY, params],
        queryFn: () => fetchTicketGet(params),
    });
}