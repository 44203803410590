import { useMutation, useQueryClient } from "react-query";
import { QUERY_KEY as repairQueryKey } from "./useRepairQuery";
// import BaseInstance from "instance/axios";
import axios from "axios";
import { BASE_URL } from "instance/axios";

type FetcherType = {
  stageId?: number;
  ticketId: number;
  stepStatus?: string;
  platCode?: number;
  customerName?: string;
  mobile?: string;
  vinCode?: string;
  context?: string;
  siteId?: string;
  pendingReasonId?: number;
  processId?: string;
};
type MutationType = "start" | "pending" | "resume" | "finish" | "save" | "process" | "restart";


//완료
const startFetcher = (params: FetcherType) =>
  // BaseInstance.ticketsTicketIdStartPut(params.ticketId);
  axios.put(`${BASE_URL}/ticket/${params.ticketId}/start`, "", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });

const pendingFetcher = (params: FetcherType) =>
  // BaseInstance.ticketsTicketIdPendingPut(params?.ticketId, {
  //   pendingReasonId: params?.pendingReasonId as number,
  // });
  axios.put(
    `${BASE_URL}/ticket/${params.ticketId}/pending`,
    {
      pendingReasonId: params?.pendingReasonId as number,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    }
  );

const resumeFetcher = (params: FetcherType) =>
  // BaseInstance.ticketsTicketIdResumePut(params.ticketId);
  axios.put(`${BASE_URL}/ticket/${params.ticketId}/resume`, "", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });

//완료
const finishFetcher = async (params: FetcherType) =>
  // BaseInstance.ticketsTicketIdFinishPut(params.ticketId);
  await axios.put(`${BASE_URL}/ticket/${params.ticketId}/finish`, "", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).catch(error => {
    alert('상세 작업을 완료하고 작업을 완료해 주세요.');
    throw error;
  });

const restartFetcher = (params: FetcherType) =>
  // BaseInstance.ticketsTicketIdStartPut(params.ticketId);
  axios.put(`${BASE_URL}/ticket/${params.ticketId}/restart`, "", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });

const saveFetcher = (params: FetcherType) => {
  axios.put(
    `${BASE_URL}/tickets/${params.ticketId}`,
    {
      ...params,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    }
  );
};

const processFetcher = (params: FetcherType) => {
  axios.put(
    `${BASE_URL}/process/${params.ticketId}`,
    {
      ...params,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    }
  );
};

const useRepairMutation = (
  mutationType: MutationType
) => {
  const queryClient = useQueryClient();

  let fetcherFunction: any;

  switch (mutationType) {
    case "start":
      fetcherFunction = startFetcher;
      break;
    case "pending":
      fetcherFunction = pendingFetcher;
      break;
    case "resume":
      fetcherFunction = resumeFetcher;
      break;
    case "finish":
      fetcherFunction = finishFetcher;
      break;
    case "save":
      fetcherFunction = saveFetcher;
      break;
    // case "process":
    //   fetcherFunction = processFetcher;
    //   break;
    case "restart":
      fetcherFunction = restartFetcher;
      break;
    default:
      throw new Error("Invalid mutation type");
  }

  return useMutation((params: Object) => fetcherFunction(params), {
    onSuccess: () => {
      let alertMessage = "";
      switch (mutationType) {
        case "start":
          alertMessage = "작업이 시작되었습니다.";
          break;
        case "pending":
          alertMessage = "작업이 지연되었습니다.";
          break;
        case "resume":
          alertMessage = "작업이 재개되었습니다.";
          break;
        case "finish":
          alertMessage = "작업이 완료되었습니다.";
          break;
        case "save":
          alertMessage = "주차 위치가 저장되었습니다.";
          break;
        // case "process":
        //   alertMessage = "processFetcher";
        //   break;
        case "restart":
          alertMessage = "작업이 재시작되었습니다.";
          break;
        default:
          throw new Error("Invalid mutation type");
      }

      window.alert(alertMessage);
      queryClient.invalidateQueries(repairQueryKey);
    },
  });
};

export default useRepairMutation;
