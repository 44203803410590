import styled from "@emotion/styled";
import axios from "axios";
import { BASE_URL } from "instance/axios";
import { useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { footerInfoAtom } from "recoil/layoutAtom";
import { colors } from "style/theme";

type USER_ROLES = "ADMIN" | "CENTER_ADMIN" | "TECHNICIAN" | "ADVISER";

export const ROLE_MAP: {
  [key in USER_ROLES]: string;
} = {
  "ADMIN": "개발자",
  "CENTER_ADMIN": "관리자",
  "TECHNICIAN": "테크니션",
  "ADVISER": "어드바이저",
}

function Index() {
  const setFooterInfo = useSetRecoilState(footerInfoAtom);

  const loadUserData = useCallback(async () => {
    const authToken = localStorage.getItem("authToken");

    if (authToken) {
      const result = await axios.get(`${BASE_URL}/whoami`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })

      setFooterInfo({
        centerName: result.data.site?.name,
        managerName: `${ROLE_MAP[result.data.role as USER_ROLES]} ${result.data.name}`,
        workbayName: result.data.workbay?.name,
      });
    }


  }, [setFooterInfo])

  useEffect(() => {
    loadUserData()
  }, [loadUserData])

  const footerInfo = useRecoilValue(footerInfoAtom);

  return (
    <Footer>
      {Object.values(footerInfo).map((info, index) => (
        <span key={index}>{info}</span>
      ))}
    </Footer>
  );
}

const Footer = styled.footer`
  width: 100%;
  height: 8rem;
  padding: 1.7rem;
  background-color: ${colors.footerColor};
  display: flex;
  align-items: center;
  justify-content: space-between;

  & span {
    color: white;
    font-size: 3.3rem;
    font-family: MBK CorpoA;
    font-weight: 400;
    letter-spacing: 0.336rem;
    word-wrap: break-word;
  }
`;

export default Index;
