import { css } from "@emotion/react";
import styled from "@emotion/styled";
import ArrowIcon from "images/svg/arrow_icon.svg";
import { useEffect, useState } from "react";
import { colors } from "style/theme";

interface Props {
  activePage?: number;
  totalData?: number;
  countPerPage: number;
  pageListDisplayLimit?: number;
  currentPg?: number;

  notifier?: (currentPage: number, ...args: any) => any;
}
type ArrowDirection = "left" | "right";

function BasePagination({
  activePage = 1,
  totalData = 0,
  countPerPage,
  pageListDisplayLimit = 5,
  notifier,
}: Props) {
  const BUTTON_SIZE = 32;
  const GAP = 10;
  // const PAGE_WRAPPER_MINWIDTH = BUTTON_SIZE * countPerPage + (GAP * countPerPage - 1);
  const LAST_PAGE = Math.ceil(totalData / countPerPage);
  
  const PAGE_LIST_LENGTH = LAST_PAGE < pageListDisplayLimit ? LAST_PAGE : pageListDisplayLimit;
  const defaultPageList = Array(PAGE_LIST_LENGTH)
    .fill(undefined)
    .map((_, idx) => idx + 1);

  const [currentPage, setCurrentPage] = useState<number>(
    activePage ? activePage : 1
  );
  const [pageList, setPageList] = useState<number[]>(defaultPageList);

  const handlePageList = (arrowDirection: ArrowDirection) => {
    if (
      arrowDirection === "left" &&
      currentPage === pageList[0] &&
      currentPage !== 1
    ) {
      setPageList((prev) => prev.map((page) => page - 1));
    }

    if (
      arrowDirection === "right" &&
      currentPage === pageList[pageList.length - 1] &&
      currentPage !== LAST_PAGE
    ) {
      setPageList((prev) => prev.map((page) => page + 1));
    }
  };

  useEffect(() => {
    setCurrentPage(activePage);
  }, [activePage]);

  useEffect(() => {
    const defaultPageList = Array(
      Math.ceil(totalData / countPerPage) < pageListDisplayLimit
        ? Math.ceil(totalData / countPerPage)
        : pageListDisplayLimit
    )
      .fill(undefined)
      .map((_, idx) => idx + 1);
    setPageList(defaultPageList);
  }, [countPerPage, pageListDisplayLimit, totalData]);



  const handleCurrentPage = (arrowDirection: ArrowDirection) => {
    const isMovingFromPageEnd =
      (arrowDirection === "left" && currentPage === 1) ||
      (arrowDirection === "right" && currentPage === LAST_PAGE);

    if (!isMovingFromPageEnd) {
      if (arrowDirection === "left") {
        setCurrentPage((prev) => prev - 1);
        notifier && notifier(currentPage - 1);
      } else {
        setCurrentPage((prev) => prev + 1);
        notifier && notifier(currentPage + 1);
      }
    }
  };

  const onArrowButtonClick = (arrowDirection: ArrowDirection) => {
    handlePageList(arrowDirection);
    handleCurrentPage(arrowDirection);
  };

  return (
    <PageBox gap={GAP}>
      <ArrowButton
        icon={ArrowIcon}
        size={BUTTON_SIZE}
        onClick={() => onArrowButtonClick("left")}
      />

      <PageWrapper>
        {pageList.map((page) => (
          <PageButton
            key={page}
            active={currentPage === page}
            size={BUTTON_SIZE}
            onClick={() => {
              setCurrentPage(page);
              notifier && notifier(page);
            }}
          >
            {page}
          </PageButton>
        ))}
      </PageWrapper>

      <ArrowButton
        icon={ArrowIcon}
        arrowDirection="right"
        size={BUTTON_SIZE}
        onClick={() => onArrowButtonClick("right")}
      />
    </PageBox>
  );
}

const PageBox = styled.div<{ gap: number }>`
  display: flex;
  gap: ${({ gap }) => `${gap}px`};
`;

const ArrowButton = styled.button<{
  icon: string;
  size: number;
  arrowDirection?: ArrowDirection;
}>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};

  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: center center;

  ${({ arrowDirection }) =>
    arrowDirection === "right" &&
    css`
      transform: scaleX(-1);
    `}
`;

const PageWrapper = styled.div<{ minWidth?: number }>`
  display: flex;
  gap: 10px;

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}
`;

const PageButton = styled.button<{ size: number; active?: boolean }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 100%;
  color: #eef0f4;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ active }) =>
    active &&
    css`
      transition: all 0.25s ease-in-out;
      background-color: ${colors.pointColorBlue};
    `}
`;

export default BasePagination;
